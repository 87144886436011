<template>
  <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M5.01172 15H8.4582C8.49164 15 8.52286 14.9833 8.54141 14.9555L11.918 9.8906C12.2889 9.3342 12.9134 9 13.5821 9H27.4413C28.1101 9 28.7345 9.3342 29.1054 9.8906L32.482 14.9555C32.5006 14.9833 32.5318 15 32.5652 15H36.0117C36.2879 15 36.5117 15.2239 36.5117 15.5V16.5C36.5117 16.7761 36.2879 17 36.0117 17H34.5117V30C34.5117 30.5523 34.064 31 33.5117 31H29.5117C28.9594 31 28.5117 30.5523 28.5117 30V27H12.5117V30C12.5117 30.5523 12.064 31 11.5117 31H7.51172C6.95943 31 6.51172 30.5523 6.51172 30V17H5.01172C4.73558 17 4.51172 16.7761 4.51172 16.5V15.5C4.51172 15.2239 4.73558 15 5.01172 15Z" fill="#256799" fill-opacity="0.2"/>
      <path d="M5.37345 11.5102L5.37346 11.5102L8.75004 6.4453C8.9355 6.1671 9.24773 6 9.58209 6H23.4413C23.7757 6 24.0879 6.1671 24.2734 6.4453L27.65 11.5101C27.65 11.5102 27.65 11.5102 27.65 11.5102C27.854 11.8162 28.1975 12 28.5652 12H29.5117V13V26H25.5117V23V22H24.5117H8.51172H7.51172V23V26H3.51172V13V12H4.4582C4.82599 12 5.16944 11.8162 5.37345 11.5102Z" stroke="#256799" stroke-width="2"/>
      <path d="M12.5117 13L16.5117 17L25.0117 8.5" stroke="#256799" stroke-width="2" stroke-linecap="round"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="36" height="36" fill="white" transform="translate(0.511719)"/>
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'CheckedCarSVG'
}
</script>
