let config;
switch (process.env.NODE_ENV) {
  case 'development':
    config = {
      ws: {
        host: 'wss://autolocal.ria.com',
        url: '/24/api/ws/logger/',
      },
    };
    break;
  case 'dev-cluster':
    config = {
      ws: {
        host: 'wss://dev-auto.ria.com',
        url: '/24/api/ws/logger/',
      },
    };
    break;
  case 'production':
  default:
    config = {
      ws: {
        host: 'wss://auto.ria.com',
        url: '/24/api/ws/logger/',
      },
    };
    break;
}
module.exports = {
  data: {
    webSocket: null,
  },
  methods: {
    createConnection() {
      return new Promise(function (resolve, reject) {
        this.webSocket = new WebSocket(config.ws.host + config.ws.url);

        this.sendMsg = false;
        this.webSocket.onopen = () => {
          setTimeout(function () {
            resolve(this.webSocket);
          }.bind(this), 200);
        };
        this.webSocket.onerror = (e) => {
          reject(e);
        };
      }.bind(this));
    },
    sendInitLog(json) {
      const data = JSON.stringify(json);
      if (!this.webSocket || [0, 2, 3].includes(this.webSocket.readyState)) {
        return this.createConnection()
          .then(() => {
            this.webSocket.send(data);
          });
      }
      this.webSocket.send(data);
    },
  },
};
