<template>
  <div class="custom-block" style="margin-top: 10px">
    <div class="custom-title size18 bold">
      <GearSVG />
      {{ $t('Запись на экспертную оценку авто') }}
    </div>
    <div class="shadow-body">
      <div class="size16">{{ $t('Запишитесь на оценку тех. состояния вашего авто') }}:</div>
      <form @submit.prevent="submit" ref="form">
        <div class="input-wrap" v-show="info['parent.id']" :class="classes('model.id')">
          <select class="grey" v-model="model" :disabled="isDisabled">
            <option :value="0">{{ $t('Модель') }}</option>
            <option v-for="item in info['model.list']" :value="item.id"> {{item.name}}</option>
          </select>
          <Tooltip ref="model.id-tooltip" :text="errors['model.id']"/>
        </div>
        <div class="input-wrap" :class="classes('body.id')">
          <select class="grey" v-model="body" :disabled="isDisabled">
            <option :value="0">{{ $t('Тип кузова') }}</option>
            <option v-for="item in bodies" :value="item.value"> {{item.name}}</option>
          </select>
          <Tooltip ref="body.id-tooltip" :text="errors['body.id']"/>
        </div>
        <div class="input-wrap" :class="classes('mileage')">
          <input type="number" :placeholder="$t('Пробег авто')" v-model.number="mileage" :disabled="isDisabled"/>
          <span class="input-label size16">{{ $t('тыс. км') }}</span>
          <Tooltip ref="mileage-tooltip" :text="errors['mileage']"/>
        </div>
        <div class="input-wrap" :class="classes('gearbox.id')">
          <select class="grey" v-model="gearbox" :disabled="isDisabled">
            <option :value="0">{{ $t('Коробка передач') }}</option>
            <option v-for="item in gearboxes" :value="item.value"> {{item.name}}</option>
          </select>
          <Tooltip ref="gearbox.id-tooltip" :text="errors['gearbox.id']"/>
        </div>
        <div class="input-wrap" :class="classes('drive.id')">
          <select class="grey" v-model="drive" :disabled="isDisabled">
            <option :value="0">{{ $t('Тип привода') }}</option>
            <option v-for="item in drives" :value="item.value"> {{item.name}}</option>
          </select>
          <Tooltip ref="drive.id-tooltip" :text="errors['drive.id']"/>
        </div>
        <div class="input-wrap" :class="classes('region.id')">
          <select class="grey" v-model="region" :disabled="isDisabled">
            <option :value="0">Область</option>
            <option v-for="item in regions" :value="item.value"> {{item.name}}</option>
          </select>
          <Tooltip ref="region.id-tooltip" :text="errors['region.id']"/>
        </div>
        <div class="input-wrap" :class="classes('city.id')">
          <select class="grey" v-model="city" :disabled="isDisabled">
            <option :value="0">{{ $t('Город') }}</option>
            <option v-for="item in cities" :value="item.value"> {{item.name}}</option>
          </select>
          <Tooltip ref="city.id-tooltip" :text="errors['city.id']"/>
        </div>
        <div class="input-wrap" :class="classes('list-technical.condition.id')">
          <select class="grey" v-model="techCondition" :disabled="isDisabled">
            <option :value="0">{{ $t('Техническое состояние') }}</option>
            <option v-for="item in techConditions" :value="item.id"> {{item.title}}</option>
          </select>
          <Tooltip ref="city.id-tooltip" :text="errors['list-technical.condition.id']"/>
        </div>
        <div class="input-wrap" :class="classes('list-paint.condition.id')">
          <select class="grey" v-model="paintCondition" :disabled="isDisabled">
            <option :value="0">{{ $t('Лакокрасочное покрытие') }}</option>
            <option v-for="item in paintConditions" :value="item.id"> {{item.title}}</option>
          </select>
          <Tooltip ref="city.id-tooltip" :text="errors['list-paint.condition.id']"/>
        </div>
        <Login v-if="!isUserLogged" v-on:userLoggedIn="userLoggedIn" ref="Login" />
      </form>
      <div class="size13 hide">*{{ $t('Менеджер свяжется с Вами в течение 10 минут и уточнит детали об авто') }}</div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Loader from '../common/Loader.vue';
import Login from '../common/Login.vue';
import Tooltip from '../common/Tooltip.vue';
import GearSVG from '../common/svgs/Gear.vue';

export default {
  name: 'TOBlock',
  data() {
    return {
      loggedIn: false,
      trySend: false,
      errors: {
        'model.id': '',
        'body.id': '',
        'gearbox.id': '',
        'drive.id': '',
        'mileage': '',
        'region.id': '',
        'city.id': '',
        'list-technical.condition.id': '',
        'list-paint.condition.id': '',
      }
    }
  },
  components: { Login, Loader, Tooltip, GearSVG },
  mounted() {
    this.$parent.$on('submit', () => {
      this.submit();
    });
    this.fetchConditions();
  },
  watch: {
    region(value){
      this.setInfoField({key: 'city.id', value: 0});
      if(value){
        this.setLoader(true);
        return this.fetchCities(value)
            .then(() => this.setLoader(false));
      }
    },
    loggedIn(value) {
      if(value && this.trySend){
        this.submit();
      }
    }
  },
  computed: {
    ...mapGetters({
      bodies: 'autoRia24/bodies',
      gearboxes: 'autoRia24/gearboxes',
      drives: 'autoRia24/drives',
      regions: 'autoRia24/regions',
      cities: 'autoRia24/cities',
      info: 'autoRia24/info',
      paintConditions: 'autoRia24/paintConditions',
      techConditions: 'autoRia24/techConditions',
      _advertisementId: 'autoRia24/advertisementId',
    }),
    isDisabled() {
      if (this._advertisementId) {
        return this._advertisementId;
      }
    },
    classes() {
      const {info = {}, errors, _advertisementId} = this;
      return field => {
        const res = [];

        if(info[field]) {
          res.push('successful');
        }
        if(errors[field] && !_advertisementId) {
          res.push('err-wrap');
        }
        if(!info[field] && _advertisementId) {
          res.push('no-point');
        }
        return res;
      }
    },
    user() {
      return this.info.user || {};
    },
    region: {
      get() {
        return this.info['region.id'];
      },
      set(value) {
        this.setInfoField({key: 'region.id', value});
      }
    },
    city: {
      get() {
        return this.info['city.id'];
      },
      set(value) {
        this.setInfoField({key: 'city.id', value});
      }
    },
    paintCondition: {
      get() {
       return this.info['list-paint.condition.id'];
      },
      set(value) {
        this.setInfoField({key: 'list-paint.condition.id', value});
        this.setLoader(true)
        this
            .calcAutoBuyPrice()
            .then(() => this.setLoader(false))
            .catch(() => this.setLoader(false))
      }
    },
    techCondition: {
      get() {
        return this.info['list-technical.condition.id'];
      },
      set(value) {
        this.setInfoField({key: 'list-technical.condition.id', value});
        this.setLoader(true)
        this
            .calcAutoBuyPrice()
            .then(() => this.setLoader(false))
            .catch(() => this.setLoader(false))
      }
    },
    model: {
      get() {
        return this.info['model.id'];
      },
      set(value) {
        this.setInfoField({key: 'model.id', value});
        this.setLoader(true)
        this
            .calcAutoBuyPrice()
            .then(() => this.setLoader(false))
            .catch(() => this.setLoader(false))
      }
    },
    body: {
      get() {
        return this.info['body.id'] || 0;
      },
      set(value) {
        this.setInfoField({key: 'body.id', value});
        this.setLoader(true)
        this
            .calcAutoBuyPrice()
            .then(() => this.setLoader(false))
            .catch(() => this.setLoader(false))
      }
    },
    gearbox: {
      get() {
        return this.info['gearbox.id'] || 0;
      },
      set(value) {
        this.setInfoField({key: 'gearbox.id', value});
        this.setLoader(true)
        this
            .calcAutoBuyPrice()
            .then(() => this.setLoader(false))
            .catch(() => this.setLoader(false))
      }
    },
    mileage: {
      get() {
        return this.info.mileage;
      },
      set(value) {
        this.setInfoField({key: 'mileage', value})
        this.setLoader(true)
        this
            .calcAutoBuyPrice()
            .then(() => this.setLoader(false))
            .catch(() => this.setLoader(false))
      }
    },
    custom: {
      get() {
        return this.info.custom;
      },
      set(value) {
        this.setInfoField({key: 'custom', value})
        this.setLoader(true)
        this
            .calcAutoBuyPrice()
            .then(() => this.setLoader(false))
            .catch(() => this.setLoader(false))
      }
    },
    drive: {
      get() {
        return this.info['drive.id'];
      },
      set(value) {
        this.setInfoField({key: 'drive.id', value})
        this.setLoader(true)
        this
            .calcAutoBuyPrice()
            .then(() => this.setLoader(false))
            .catch(() => this.setLoader(false))
      }
    },
    isUserLogged() {
      return Boolean(this.user.id) || this.loggedIn;
    }
  },
  methods: {
    ...mapMutations({
      setInfoField: 'autoRia24/setInfoField',
      setLoader: 'autoRia24/setLoader'
    }),
    ...mapActions({
      calcAutoBuyPrice: 'autoRia24/calcAutoBuyPrice',
      addExistingAuto: 'autoRia24/addExistingAuto',
      fetchCities: 'autoRia24/fetchCities',
      fetchConditions: 'autoRia24/getConditions',
      addAuto: 'autoRia24/addAuto',
    }),
    userLoggedIn() {
      this.loggedIn = true;
    },
    checkFrontParams() {
      if(!this.gearbox){
        this.errors['gearbox.id'] = ['Поле "', this.$t('Коробка передач'), '" ', this.$t('обязательно для заполнения')].join('');
      } else {
        this.errors['gearbox.id'] = '';
      }

      if(!this.drive){
        this.errors['drive.id'] = ['Поле "', this.$t('Тип привода'), '" ', this.$t('обязательно для заполнения')].join('');
      } else {
        this.errors['drive.id'] = '';
      }

      if(!this.techCondition){
        this.errors['list-technical.condition.id'] = ['Поле "', this.$t('Техническое состояние'), '" ', this.$t('обязательно для заполнения')].join('');
      } else {
        this.errors['list-technical.condition.id'] = '';
      }


      if(!this.paintCondition){
        this.errors['list-paint.condition.id'] = ['Поле "', this.$t('Лакокрасочное покрытие'), '" ', this.$t('обязательно для заполнения')].join('');
      } else {
        this.errors['list-paint.condition.id'] = '';
      }

      return Boolean(this.techCondition && this.drive && this.gearbox && this.paintCondition)
    },
    zeroErrors() {
      this.errors = {
        'body.id': '',
        'model.id': '',
        'mileage': '',
        'region.id': '',
        'city.id': ''
      }
    },
    submit() {
      // const {info} = this;
      // console.log(info);
      // return;
      this.trySend = true;

      if(this.$refs.Login && !this.isUserLogged){
        this.$refs.Login.$emit('runScenario');
        return;
      } else if(this._advertisementId) {
        this.setLoader(true);
        return this
            .addExistingAuto()
            .then((data) =>
              this.$router.push(this.langPrefix + '/autoBuy/' + this._advertisementId)
            )
            .catch(error => {
              alert('Ошибка');
              this.setLoader(false);
              this.trySend = false;
            })

      } else if(this.checkFrontParams()) {
        this.zeroErrors();
        this.setLoader(true);
        return this
            .addAuto()
            .then(data => {
              if(data.errors && data.errors.length){
                data.errors.map(({elementId, text}) => {
                  this.errors[elementId] = text;
                })
              } else if(Number(data._id)){
                this.$router.push(this.langPrefix + '/autoBuy/' + data._id);
              }
              this.setLoader(false);
            })
            .catch((error) => {
              alert("Ошибка");
              this.setLoader(false);
              this.trySend = false;
            })
            ;
      }
    }
  },
  i18n: {
    messages: {
      ru: {
        'Лакокрасочное покрытие': 'Лакокрасочное покрытие',
        'Техническое состояние': 'Техническое состояние',
        'Запись на экспертную оценку авто': 'Запись на экспертную оценку авто',
        'Запишитесь на оценку тех. состояния вашего авто': 'Запишитесь на оценку тех. состояния вашего авто',
        'Тип кузова': 'Тип кузова',
        'Модель': 'Модель',
        'Коробка передач': 'Коробка передач',
        'Пробег авто': 'Пробег авто',
        'тыс. км': 'тыс. км',
        'Тип привода': 'Тип привода',
        'Город': 'Город',
        'Продать за 24 часа': 'Продать за 24 часа',
        'обязательно для заполнения': 'обязательно для заполнения',
        'Менеджер свяжется с Вами в течение 10 минут и уточнит детали об авто': 'Менеджер свяжется с Вами в течение 10 минут и уточнит детали об авто',
      },
      uk: {
        'Лакокрасочное покрытие': 'Лакофарбове покриття',
        'Техническое состояние': 'Технічний стан',
        'Запись на экспертную оценку авто': 'Запис на експертну оцінку авто',
        'Запишитесь на оценку тех. состояния вашего авто': 'Запишіться на оцінку тех. стану вашого авто',
        'Коробка передач': 'Коробка передач',
        'Тип кузова': 'Тип кузову',
        'Модель': 'Модель',
        'Пробег авто': 'Пробіг авто',
        'тыс. км': 'тыс. км',
        'Тип привода': 'Тип приводу',
        'Продать за 24 часа': 'Продати за 24 години',
        'Город': 'Місто',
        'обязательно для заполнения': "обов'язково для заповнення",
        'Менеджер свяжется с Вами в течение 10 минут и уточнит детали об авто': "Менеджер зв'яжеться з Вами протягом 10 хвилин і уточнить деталі про авто",
      }
    }
  }
}
</script>
