<template>
  <div
      class="input-wrap"
      :class="{'err-wrap': Boolean(errText), 'no-point': !required, successful: required && field}">
    <select class="grey grey-text" v-model="field">
      <template v-for="data in options">
        <option :value="data.value" v-if="data.value === 0">{{ label }}</option>
        <option :value="data.value" v-else>{{ data.text }}</option>
      </template>
    </select>
    <Tooltip :text="errText"/>
<!--    <Autocomplite-->
<!--        v-if="autocomplete"-->
<!--    />-->
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Autocomplite from '../../../../common/Autocomplite.vue';
import Tooltip from '../../../../common/Tooltip.vue';

export default {
  name: 'singleSelect',
  components: {Autocomplite, Tooltip},
  props: {
    id: String,
    errText: String,
    options: Array,
    label: String,
    onChange: Array,
    required: Boolean,
    autocomplete: Boolean
  },
  methods: {
    ...mapActions({
      elementChanged: 'autoRia24/elementChanged'
    }),
    ...mapMutations({
      setInfoField: 'autoRia24/setInfoField',
      setLoader: 'autoRia24/setLoader'
    })
  },
  computed: {
    ...mapGetters({
      infoField: 'autoRia24/infoField'
    }),
    selected() {
      const [option = {}] = [] = this.options.filter(({value}) => this.field == value);
      return option
    },
    requireUpdateForm() {
      return this.onChange.includes('updateForm')
    },
    field: {
      get() {
        return this.infoField(this.id) || 0;
      },
      set(value) {
        this.setInfoField({key: this.id, value});
        if(this.id.includes('.id')) {
          const nameKey = this.id.replace('id', 'name');
          this.setInfoField({key: nameKey, value: this.selected.text})
        }
        if(this.requireUpdateForm){
          this.setLoader(true)
          return this
              .elementChanged(this.id)
              .then(() => {
                this.setLoader(false);
              });
        }
      }
    }
  }
}
</script>
