<template>
  <div class="buy-car-foto photo-responsive" :data-id="photoId">
    <picture>
      <img
          loading="lazy"
          :ref="`photo_${photoId}`"
          onload="this.closest('.photo-responsive').classList.add('loaded');"
          :src="url"
          @error="errorRetry"
      />
    </picture>
    <div class="del" @click="delPhoto" v-if="!this.advertisementId"></div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
export default {
  name: 'Item',
  data() {
    return {
      tryCount: 0,
    }
  },
  props: {
    url: String,
    photoId: Number,
  },
  computed: {
    ...mapGetters({
      advertisementId: 'autoRia24/advertisementId',
    }),
    key() {
      return `photo_${this.photoId}`
    },
    imageEl() {
      return this.$refs[this.key];
    }
  },
  methods: {
    ...mapActions({
      _deletePhoto: 'autoRia24/deletePhoto'
    }),
    ...mapMutations({
      setLoader: 'autoRia24/setLoader'
    }),
    delPhoto() {
      this.setLoader(true);
      this._deletePhoto(this.photoId)
          .then(() => {
            this.setLoader(false)
          })
          .catch((error) => {
            console.error(error)
            this.setLoader(false)
          })
      ;
    },
    errorRetry() {
      if(this.tryCount < 100) {
        setTimeout(() => {
          this.imageEl.src = this.url + `?z=${Date.now()}`;
          this.tryCount++;
        }, 2000)
      }
    },
  }
}
</script>
