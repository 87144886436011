<template>
  <svg style="margin-right: 8px;" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.511719 10.9968C0.511719 6.26519 4.16552 2.38264 8.8243 2.01982V1.41388H7.15057C6.75456 1.41388 6.44447 1.10342 6.44447 0.706938C6.44447 0.310454 6.75456 0 7.15057 0H11.8056C12.2016 0 12.5117 0.310454 12.5117 0.706938C12.5117 1.10342 12.2016 1.41388 11.8056 1.41388H10.2365V2.01982C11.9625 2.15822 13.5316 2.76043 14.8392 3.72919L15.6163 2.95119L15.2876 2.62203C15.0111 2.34524 15.0111 1.89639 15.2876 1.6196C15.564 1.34281 16.0123 1.34281 16.2888 1.6196L17.9252 3.2579C18.2016 3.53469 18.2016 3.98354 17.9252 4.26033C17.7869 4.39873 17.6151 4.46606 17.4245 4.46606C17.234 4.46606 17.0622 4.39873 16.9239 4.26033L16.5952 3.93118L15.8891 4.63811C17.5105 6.26145 18.5117 8.52067 18.5117 10.9893C18.5117 15.9604 14.4768 20 9.51172 20C4.54659 20 0.511719 15.9678 0.511719 10.9968ZM9.51172 3.40004C5.31994 3.40004 1.90898 6.80007 1.90898 11.0118C1.90898 15.2085 5.32367 18.6235 9.51172 18.6235C13.6998 18.6235 17.1145 15.2048 17.1145 11.0118C17.1145 6.81878 13.7035 3.40004 9.51172 3.40004ZM9.51172 11.5841C9.1157 11.5841 8.80562 11.2736 8.80562 10.8921V6.52702C8.80562 6.13054 9.1157 5.82009 9.51172 5.82009C9.90773 5.82009 10.2178 6.13054 10.2178 6.52702V10.1702H12.5267C12.9227 10.1702 13.2328 10.4806 13.2328 10.8771C13.2328 11.2736 12.9227 11.5841 12.5267 11.5841H9.51172Z" fill="white"/>
  </svg>
</template>
<script>
export default {
  name: 'ClockSVG'
}
</script>
