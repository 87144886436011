<template>
  <div>
    <div class="num-wrap">
      <div
          class="size13"
          :class="{'adv-id': this.advertisementId, 'car-num': !this.advertisementId}"
      >
        {{ filler }}
      </div>
      <a :href="`${langPrefix}/24/`" class="link link-dotted size13">{{ $t('Изменить авто') }}</a>
    </div>
    <h3 class="size16 bold" v-if="info.reportId">{{ $t('По данному гос. номеру известно следующее') }}:</h3>
    <div class="car-info size16" v-if="isAutofilled">
      <div class="car-info-item" v-for="item of infoList">
        {{item}}
      </div>
    </div>
    <Photos />
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import Photos from './Photos/index.vue';

export default {
  name: 'Advertisement',
  components: {
    Photos
  },
  computed: {
    ...mapGetters({
      info: 'autoRia24/info',
      advertisementId: 'autoRia24/advertisementId',
      _plateNumber: 'autoRia24/plateNumber',
      isAutofilled: 'autoRia24/isAutofilled'
    }),
    infoList() {
      const { brandName, modelName, info: {year}, engineVolume, fuelName} = this;
      const engineInfo = engineVolume ? `${this.$t('Объем двигателя')} ${engineVolume} л.` : '';
      return [`${brandName} ${modelName} ${year}`, engineInfo, fuelName].filter(item => item)
    },
    brandName() {
      return this.info['brand.name'];
    },
    modelName() {
      return this.info['model.name'];
    },
    engineVolume(){
      return this.info['engine.volume.liters'];
    },
    fuelName() {
      return this.info['fuel.name'];
    },
    filler() {
      const { _plateNumber: plateNumber, advertisementId } = this;
      if (advertisementId) {
        return advertisementId;
      }
      // AA1105OI -> AA 1105 OI
      return [plateNumber.slice(0, 2), plateNumber.slice(2, 6), plateNumber.slice(6)].join(' ');
    }
  },
  i18n: {
    messages: {
      ru: {
        'Изменить авто': 'Изменить авто',
        'По данному гос. номеру известно следующее': 'По данному гос. номеру мы нашли',
        'Объем двигателя': 'Объем двигателя'
      },
      uk: {
        'Изменить авто': 'Змінити авто',
        'По данному гос. номеру известно следующее': 'По даному держ. номеру ми знайшли',
        'Объем двигателя': "Об'єм двигуна"
      }
    }
  }
}
</script>
