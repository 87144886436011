<template>
  <div class="wrapped">
    <div class="car-wrap">
      <Item
              v-for="(photo, key) in photos"
              :key="key"
              :url="photo.uri"
              :photoId="photo.id"
      />
    </div>
    <form class="add-car-foto" v-show="!advertisementId">
      <input
          style="display: none"
          type="file"
          id="add-car-foto"
          accept="image/jpeg,image/png,image/jpg"
          multiple="multiple"
          @change="load"
      />
      <label class="add-car-foto-label" for="add-car-foto">{{$t('Добавить фото')}}</label>
    </form>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Item from './Item.vue';
export default {
  name: 'Photos',
  data() {
    return {
      queue: [],
      sortingIndex: 0,
    }
  },
  computed: {
    ...mapGetters({
      sessionId: 'autoRia24/sessionId',
      advertisementId: 'autoRia24/advertisementId',
      photos: 'autoRia24/photos'
    }),
  },
  components: {
    Item
  },
  watch: {
    queue(q) {
      if(q.length) {
        this.setLoader(true);
        const files = q.slice(0, 4);

        return Promise
            .all(files.map(this.loadPhoto))
            .then((results) => {
              results.map((res) => res && this.photo(res));
              this.queue.splice(0, files.length);
            })
            .catch(err => {
              this.setLoader(false);
            })
      }

      this.setLoader(false);
    },
    photos(photos) {
      if(!photos.length){
        this.sortingIndex = 0;
      }
    }
  },
  methods: {
    ...mapActions({
      addPhoto: 'autoRia24/addPhoto'
    }),
    ...mapMutations({
      photo: 'autoRia24/photo',
      setLoader: 'autoRia24/setLoader',
    }),
    loadPhoto(file) {
      let formData = new FormData;
      formData.append('file0', file);
      return this
          .addPhoto(formData)
          .then(payload => {
            payload.sortingIndex = payload.sortingIndex || this.sortingIndex;
            this.sortingIndex++;
            payload.uri = this.makePhotoUri(payload)
            return payload;
          })
          .catch((err) => {
            console.error(err)
          })
    },
    load(e){
      const { currentTarget: { files } = {} } = {} = e;
      if (!files || !files.length) return;
      this.queue.push(...files);
    },
    makePhotoUri(photo) {
      const base = `https://${photo.staticId}.riastatic.com/images/original/resized/158x118/auto/photo/`;
      const id = String(photo.id);
      return base + id.slice(0, id.length - 4) + "/" + id.slice(0, id.length - 2) + "/" + id + "/" + id + '.jpeg';
    }
  },
  i18n: {
    messages: {
      ru: {
        'Добавить фото': 'Добавить фото'
      },
      uk: {
        'Добавить фото': 'Додати фото'
      }
    }
  }
}
</script>
