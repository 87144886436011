<template>
  <div class="tooltip-add open" v-html="text" v-if="text"></div>
</template>
<script>
export default {
  name: 'Tooltip',
  props: {
    text: String
  }
}
</script>
