<template>
  <div>
    <div class="input-wrap" :class="{successful: phoneChecked, 'err-wrap': error}">
      <input type="text" @input="checkPhone" v-model="phone" placeholder="Номер телефону (Без +38)" maxlength="10" />
      <Tooltip v-if="error" :text="error"/>
    </div>
    <div class="input-wrap" v-if="phoneChecked && scenario === 'login'" :class="{successful: scenario === 'sucesslogin'}">
      <input type="password" placeholder="Пароль" v-model="password"/>
      <a href="javascript:void(0)" style="margin-left: 15px" @click="forgotPassword"> {{ $t('Я забыл пароль') }} </a>
    </div>
    <div
        class="input-wrap"
        v-if="phoneChecked && scenario === 'mobile/change_pass'"
        :class="{'err-wrap': newPassword.pass !== newPassword.duplicate}"
    >
      <input type="password" :placeholder="$t('Новый пароль')" v-model="newPassword.pass"/>
      <Tooltip v-if="newPassword.pass !== newPassword.duplicate" :text="$t('Пароли не совпадают')"/>
    </div>
    <div class="input-wrap" v-if="phoneChecked && scenario === 'mobile/change_pass'">
      <input type="password" :placeholder="$t('Повторите новый пароль')" v-model="newPassword.duplicate"/>
      <a href="javascript:void(0)" class="link link-dotted size13 ml-10" @click="changePassword">{{ $t('Сохранить пароль') }}</a>
    </div>
    <div class="input-wrap" v-if="phoneChecked && scenario === 'registration'">
      <input type="text" v-model="name" :placeholder="$t('Имя')"/>
    </div>
    <div class="input-wrap" v-if="phoneChecked && scenario === 'registration'">
      <input type="text" placeholder="СМС код" v-model="smsCode" />
    </div>
    <div class="input-wrap" v-if="phoneChecked && scenario === 'restore'">
      <input type="text" :placeholder="$t('Введите код из СМС')" v-model="tmpPassword" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import Tooltip from './Tooltip.vue';

export default {
  name: 'Login',
  data() {
    return {
      phone: '',
      password: '',
      phoneChecked: false,
      error: '',
      name: '',
      tmpPassword: '',
      scenario: '',
      smsCode: '',
      newPassword: {
        pass: '',
        duplicate: ''
      }
    }
  },
  components: {Tooltip},
  created() {
    this.$on('runScenario', this.runScenario);
  },
  watch: {
    tmpPassword(tmp) {
      if(tmp.length >= 8){
        this.error = '';
        this
            .runScenario()
            .then((data) => {
              if(!this.error){
                this.scenario = 'mobile/change_pass';
                this.tmpPassword = '';
              }
              this.setLoader(false);
            })
      }
    }
  },
  methods: {
    ...mapActions({
      _checkPhone: 'autoRia24/checkPhone',
      callPassportScenario: 'autoRia24/callPassportScenario',
      changeName: 'autoRia24/changeName'
    }),
    ...mapMutations({
      setLoader: 'autoRia24/setLoader',
    }),
    runScenario() {
      if(!this.phone) {
        this.error = this.$t('Поле "Номер телефона" обязательное');
        return;
      } else if(this.phone.length < 8) {
        this.error = this.$t('Телефон формата 0731XX8X32');
        return;
      } else {
        this.error = '';
      }

      this.setLoader(true)
      return this
        .callPassportScenario({
          phone: this.phone,
          password: this.password,
          scenario: this.scenario,
          tmp_password: this.tmpPassword,
          new_password: this.newPassword.pass,
          sms_code: this.smsCode,

        })
        .then(data => {
          if (data && data.errors &&  data.errors.length) {
            const {errors: [{message} = {}] = []} = {} = data;
            this.error = message;
            this.setLoader(false);
          }
          if (data.status === 2) {
            //випадок при зміні пароля
            this.clearComponent();
            this.$emit('userLoggedIn');
            this.setLoader(false);
          }

          if (this.scenario === 'registration' && data.scenario === 'errorlogin') {
            this.changeName(this.name);
          } else if (data.scenario === 'sucesslogin') {
            //випадок при зміні пароля
            this.clearComponent();
            this.$emit('userLoggedIn');
            this.setLoader(false);
          }

        })
    },
    forgotPassword() {
      this.scenario = 'remember-password';
      if(this.isFormValid()) {
        this.password = '';
        this.runScenario()
            .then(() => {
              this.setLoader(false);
            })
            .then(() => {
              this.scenario = 'restore';
            })
        ;
      }
    },
    changePassword() {
      this.runScenario()
    },
    checkPhone() {
      if (this.phone.length === 10) {
        this.setLoader(true);
        return this._checkPhone(this.phone)
            .then(data => {
              this.scenario = data.scenario;
              this.phoneChecked = true;
              this.setLoader(false);
              return data;
            })
            .catch(error => {
              console.error(error);
              this.setLoader(false);
            });
      } else {
        this.clearComponent();
        return Promise.resolve();
      }
    },
    isFormValid() {
      if (this.scenario === 'login' && (!this.password || !this.phone)) return false;
      if (this.scenario === 'registration' && (!this.smsCode || !this.phone || !this.name)) return false;
      if (this.scenario === 'restore' &&
         (!this.newPassword.pass ||
          !this.newPassword.duplicate ||
          this.newPassword.duplicate !== this.newPassword.pass)) return false;
      return true;
    },
    clearComponent(){
      this.phoneChecked = false;
      this.scenario = '';
      this.password = '';
      this.error = '';
      this.tmpPassword = '';
      this.name = '';
      this.newPassword = {
        pass: '',
        duplicate: ''
      }
    }
  },
  i18n: {
    messages: {
      ru: {
        'Я забыл пароль': 'Я забыл пароль',
        'Новый пароль': 'Новый пароль',
        'Пароли не совпадают': 'Пароли не совпадают',
        'Повторите новый пароль': 'Повторите новый пароль',
        'Сохранить пароль': 'Сохранить пароль',
        'Имя': 'Имя',
        'Введите код из СМС': 'Введите код из СМС',
        'Поле "Номер телефона" обязательное': 'Поле "Номер телефона" обязательное',
      },
      uk: {
        'Я забыл пароль': 'Я забув пароль',
        'Новый пароль': 'Новий пароль',
        'Пароли не совпадают': 'Паролі не збігаються',
        'Повторите новый пароль': 'Повторіть новий пароль',
        'Сохранить пароль': 'Зберегти пароль',
        'Имя': "Ім'я",
        'Введите код из СМС': 'Введіть код з СМС',
        'Поле "Номер телефона" обязательное': 'Поле "Номер телефону" обов\'язкове',
      }
    }
  }
}
</script>
