<template>
  <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.5109 5C21.7072 5 22.8709 5.14005 23.9863 5.40465L24.9499 9.93242C25.6811 10.2553 26.3705 10.6555 27.0078 11.1227L31.4096 9.69367C33.0028 11.3778 34.2098 13.4311 34.889 15.7117L31.4475 18.8121C31.4894 19.2023 31.5109 19.5986 31.5109 20C31.5109 20.4014 31.4894 20.7977 31.4475 21.188L34.889 24.2883C34.2098 26.569 33.0027 28.6222 31.4095 30.3064L27.0077 28.8774C26.3705 29.3445 25.6811 29.7447 24.9499 30.0676L23.9863 34.5953C22.8709 34.8599 21.7073 35 20.5109 35C19.3145 35 18.1508 34.8599 17.0354 34.5953L16.0718 30.0675C15.3406 29.7447 14.6512 29.3445 14.014 28.8773L9.61227 30.3063C8.01906 28.6222 6.81202 26.5689 6.13282 24.2882L9.57427 21.188C9.53236 20.7977 9.51086 20.4014 9.51086 20C9.51086 19.5986 9.53236 19.2023 9.57426 18.8121L6.13281 15.7118C6.812 13.4312 8.01903 11.3779 9.61222 9.69371L14.0139 11.1227C14.6512 10.6555 15.3406 10.2553 16.0718 9.93244L17.0354 5.40469C18.1509 5.14006 19.3145 5 20.5109 5Z" fill="#256799" fill-opacity="0.2"/>
    <circle cx="16.5117" cy="16" r="4" stroke="#256799" stroke-width="2"/>
    <path d="M19.9718 6.14058L20.0782 6.64068L20.5459 6.84721C21.2103 7.14057 21.837 7.50433 22.4165 7.92919L22.8295 8.23194L23.3166 8.07383L27.1027 6.84466C28.2498 8.17049 29.1517 9.71313 29.7387 11.4021L26.7781 14.0691L26.3986 14.411L26.4532 14.9188C26.4913 15.2737 26.5109 15.6344 26.5109 16C26.5109 16.3656 26.4913 16.7264 26.4532 17.0812L26.3986 17.5891L26.7781 17.931L29.7387 20.598C29.1516 22.2869 28.2498 23.8296 27.1027 25.1554L23.3165 23.9262L22.8295 23.7681L22.4165 24.0709C21.837 24.4957 21.2103 24.8594 20.5459 25.1528L20.0782 25.3593L19.9718 25.8594L19.1431 29.7531C18.2913 29.9151 17.4114 30 16.5109 30C15.6103 30 14.7305 29.9151 13.8785 29.7531L13.0499 25.8594L12.9435 25.3593L12.4757 25.1528C11.8114 24.8594 11.1847 24.4957 10.6052 24.0708L10.1923 23.7681L9.70522 23.9262L5.9191 25.1553C4.77198 23.8295 3.87014 22.2868 3.28314 20.5979L6.24358 17.931L6.62309 17.5891L6.56855 17.0812C6.53045 16.7264 6.51086 16.3656 6.51086 16C6.51086 15.6344 6.53045 15.2737 6.56855 14.9188L6.62308 14.411L6.24358 14.0691L3.28313 11.4022C3.87012 9.7132 4.77195 8.17055 5.91906 6.8447L9.70515 8.07384L10.1922 8.23196L10.6052 7.9292C11.1847 7.50434 11.8114 7.14059 12.4758 6.84723L12.9435 6.64069L13.0499 6.14059L13.8786 2.2469C14.7305 2.0849 15.6104 2 16.5109 2C17.4114 2 18.2913 2.08489 19.1431 2.24688L19.9718 6.14058Z" stroke="#256799" stroke-width="2"/>
  </svg>
</template>
<script>
export default {
  name: 'GearSVG'
}
</script>
