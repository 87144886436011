<template>
  <div class="input-wrap year no-point">
    <div class="input-label">{{ label }}</div>
    <input :type="type" :placeholder="placeholder" :maxlength="maxlength" v-model.number="field"/>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'singleInput',
  props: {
    id: String,
    type: String,
    label: String,
    maxlength: Number,
    placeholder: String
  },
  computed: {
    ...mapGetters({
      infoField: 'autoRia24/infoField'
    }),
    field: {
      get() {
        return this.infoField(this.id) || 0;
      },
      set(value) {
        this.setInfoField({key: this.id, value});
      }
    }
  },
  methods: {
    ...mapMutations({
      setInfoField: 'autoRia24/setInfoField',
    })
  }
}
</script>
