<template>
  <div class="questions">
    <div class="ava photo-responsive">
      <picture>
        <img loading="lazy" onload="this.closest('.photo-responsive').classList.add('loaded');" src="https://cdn.riastatic.com/docs/pictures/common/1/114/11410/11410.jpg" alt=""/>
      </picture>
    </div>
    <div class="wrap">
      <h5 class="size18 bold">{{ $t('Остались вопросы? Готов проконсультировать') }}</h5>
      <div class="size13">{{ $t('Менеджер контроля качества AUTO.RIA') }} <br/><span class="bold size15">{{ $t('Дмитрий Ищенко') }}</span></div>
      <a href="tel:+380730632383" class="button-add" @click="sendStatSlon">
        <svg width="16" style="margin-right: 8px;" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.4286 15C9 15 6.5 12.5 4.71429 10.6429C4.42857 10.3571 4.21429 10.1429 4 9.92857C2.14286 8.14286 1 6 1 4.28571C1 0.285715 3.78571 0 3.92857 0C4 0 4.07143 0 4.07143 0C5.85714 0.714286 6.85714 1.57143 7 2.71429C7.21429 4.14286 6 5.42857 5.21429 6.07143C5.14286 6.07143 5.14286 6.14286 5.14286 6.21429C5.14286 6.28571 5.14286 6.28571 5.21429 6.35714L9.5 10.6429C9.5 10.6429 9.57143 10.7143 9.64286 10.7143C9.71429 10.7143 9.71429 10.7143 9.78571 10.6429C10.3571 10.0714 11.5714 9 12.8571 9C14.1429 9 15.0714 10 15.7857 11.8571C15.7857 11.9286 15.7857 11.9286 15.7857 12C15.7143 12.1429 15.3571 14.9286 11.4286 15Z" fill="white"/>
        </svg>
        (073) 063 23 83</a>
      <a class="link" href="mailto:dmytro.ishchenko@ria.com">dmytro.ishchenko@ria.com</a>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Contacts',
  i18n: {
    messages: {
      ru: {
        'Остались вопросы? Готов проконсультировать': 'Остались вопросы? Готов проконсультировать',
        'Менеджер контроля качества AUTO.RIA': 'Менеджер контроля качества AUTO.RIA',
        'Андрей': 'Андрей',
        'Дмитрий Ищенко': 'Дмитрий Ищенко'
      },
      uk: {
        'Остались вопросы? Готов проконсультировать': 'Залишилися питання? Готовий проконсультувати',
        'Менеджер контроля качества AUTO.RIA': 'Менеджер контролю якості AUTO.RIA',
        'Андрей': 'Андрій',
        'Дмитрий Ищенко': 'Дмитро Іщенко'
      }
    }
  },
  computed: {
    ...mapGetters({
      info: 'autoRia24/info',
      userData: 'Common/userData',
    })
  },
  methods: {
    formPrevUrl() {
      const vueOpenHistory = this.$store.state.route.from.fullPath !== '/' ;
      const pageDirectOpen = document.referrer.length === 0;
      let prevUrl = undefined;
      switch(true) {
        case vueOpenHistory:
          prevUrl = window.location.origin + this.$store.state.route.from.fullPath;
          break;
        case !pageDirectOpen :
          prevUrl = document.referrer;
          break;
      }
      return prevUrl
    },
    sendStatSlon() {
      const userId = this.userData ? (this.userData.userId ? this.userData.userId : (this.info && this.info.user && this.info.user.id ? this.info.user.id : 0)) : 0;
      this._slonik({
        event_id: 619,
        screentype: this.isDesktop ? 1 : 2,
        owner_id: userId,
        current_url:window.location.href,
        previous_url: this.formPrevUrl()
      });
    },
  }
}
</script>
