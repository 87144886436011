import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Description',
	computed: {
		...mapGetters({
			info: 'autoRia24/info',
		}),
		description: {
			get() {
				return this.info[`description.${this.langCode}`];
			},
			set(value) {
				return this.setInfoField({key: `description.${this.langCode}`, value});
			}
		}
	},
	methods: {
		...mapMutations({
			setInfoField: 'autoRia24/setInfoField'
		})
	},
	i18n: {
		messages: {
			ru: {
				'Расскажите больше о своём авто': 'Расскажите больше о своём авто',
				'Поделитесь преимуществами, расскажите об особенностях вашего авто': 'Поделитесь преимуществами, расскажите об особенностях вашего авто',
			},
			uk: {
				'Расскажите больше о своём авто': 'Розкажіть більше про своє авто',
				'Поделитесь преимуществами, расскажите об особенностях вашего авто': 'Поділіться перевагами, розкажіть про особливості вашого авто',
			}
		}
	}
};
