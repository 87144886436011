<template>
  <div class="no-info">
    <div class="size16">
      <span class="bold">{{ $t('Параметры вашего авто') }}</span>
    </div>
    <div class="no-num size16 bold">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Icon20 / i20_alert_important">
          <circle id="Ellipse" cx="10" cy="10" r="10" fill="#F29718"/>
          <path id="Rectangle" d="M9 4C9 3.44772 9.44772 3 10 3C10.5523 3 11 3.44772 11 4V12C11 12.5523 10.5523 13 10 13C9.44772 13 9 12.5523 9 12V4Z" fill="white"/>
          <path id="Rectangle 2" d="M9 16C9 15.4477 9.44772 15 10 15C10.5523 15 11 15.4477 11 16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16Z" fill="white"/>
        </g>
      </svg>
      <span>{{ $t('По данному гос.номеру не найдено информации в реестрах МВД') }}</span>
    </div>
    <hr>
    <h5 class="size16 bold">{{ $t('Пожалуйста, введите данные вручную') }}:</h5>
    <form>
      <template v-for="element in formElements">
        <singleSelect
            v-if="element.template === 'singleSelect'"
            :ref="element.data.id"
            :id="element.data.id"
            :options="element.data.options"
            :label="element.label"
            :onChange="element.data.onChange"
            :autocomplete="element.data.autocomplete"
            :errText="errText(element.data.id)"
            :required="isFieldRequired(element.data.id)"
        />
        <singleInput
            v-if="element.template === 'singleInput'"
            :ref="element.data.id"
            :id="element.data.id"
            :label="element.label"
            :placeholder="element.data.attributes.placeholder"
            :type="element.data.attributes.type"
            :maxlength="element.data.attributes.maxlength"
        />
      </template>
      <button class="button size16" @click.prevent="submit">{{ $t('Оценить стоимость авто') }}</button>
    </form>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import singleSelect from './templates/singleSelect.vue';
import singleInput from './templates/singleInput.vue';

export default {
  name: 'Form',
  components: {singleSelect, singleInput},
  data() {
    return {
      'categories.main.id': '',
      'brand.id': '',
      'model.id': '',
      'fuel.id': '',
      'year': '',
      'engine.volume.liters': '',
    }
  },
  computed: {
    ...mapGetters({
      formElements: 'autoRia24/formElements',
      info: 'autoRia24/info'
    }),
    category() {
      return this.info['categories.main.id'];
    },
    region() {
      return this.info['region.id'];
    },
    errText(){
      return (id) => {
        return this[id];
      }
    }
  },
  watch: {
    category(val) {
      this.fetchBodies(val);
    },
    region(value) {
      this.fetchCities(value)
    }
  },
  methods: {
    ...mapMutations({
      setLoader: 'autoRia24/setLoader'
    }),
    ...mapActions({
      setFilled: 'autoRia24/setFilled',
      fetchBodies: 'autoRia24/fetchBodies',
      fetchCities: 'autoRia24/fetchCities'
    }),
    isFieldRequired(id){
      return {
        'categories.main.id': true,
        'brand.id': true,
        'model.id': true,
        'fuel.id': true,
        'year': true,
        'engine.volume.liters': true,
      }[id]
    },
    submit() {
      if(this.checkErrors()){

        this.setLoader(true);
        this.setFilled().then(() => this.setLoader(false));
      }
    },
    checkErrors(){
      let keys = [];
      for (let item of Object.keys(this.$data)) {
       if(!this.info[item] && typeof this[item] === 'string') {
         let field = {
           'categories.main.id': 'Тип транспорта',
           'brand.id': 'Марка авто',
           'model.id': 'Модель авто',
           'fuel.id': 'Паливо',
           'year': 'Год выпуска',
           'engine.volume.liters': 'Двигатель',
         }[item];
         keys.push(item);
         this[item] = `Поле "${field}" ${this.$t('обязательно для заполнения')}`;
       }else if(this.info[item] && typeof this[item] === 'string'){
         this[item] = '';
       }
      }
      return keys.every(key => Boolean(this.info[key]));
    }
  },
  i18n: {
    messages: {
      ru: {
        'Параметры вашего авто': 'Параметры вашего авто',
        'Пожалуйста, введите данные вручную': 'Пожалуйста, введите данные вручную',
        'Оценить стоимость авто': 'Оценить стоимость авто',
        'По данному гос.номеру не найдено информации в реестрах МВД': 'По данному гос. номеру не найдено информации в реестрах МВД',
        'обязательно для заполнения': 'обязательно для заполнения',
      },
      uk: {
        'Параметры вашего авто': 'Параметри вашого авто',
        'Пожалуйста, введите данные вручную': 'Будь-ласка, введіть дані вручну',
        'Оценить стоимость авто': 'Оцінити вартість авто',
        'По данному гос.номеру не найдено информации в реестрах МВД': 'За даним держ. номером не знайдено інформації в реєстрах МВС',
        'обязательно для заполнения': "обов'язково для заповнення",
      }
    }
  }
}
</script>
