<template>
  <div class="app-content m-padding">
    <Loader />
    <h1 class="bold size24">{{ $t('Продайте авто за 24 часа') }}!</h1>
    <Advertisement />
    <Form v-if="!isAutofilled" />
    <TOBlock v-if="isAutofilled" />
    <PriceBlock v-if="isAutofilled" v-on:submit="sendAction"/>
    <Contacts />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

const storeModule = require("../../../store/autoRia24");
import Loader from '../common/Loader.vue';
import Contacts from '../common/Contacts.vue';
import TOBlock from './TOBlock.vue';
import PriceBlock from './PriceBlock.vue';
import Advertisement from './Advertisement/index.vue';
import Form from './Advertisement/Form/index.vue';

const seo = {
  2: {
    title: 'Продажа авто за 24 часа напрямую от AUTO.RIA',
  },
  4: {
    title: 'Продаж авто за 24 години напряму від AUTO.RIA'
  }
}

export default {
  name: 'AutoRia24',
  mixins: [require('../../../mixins/AutoRia24.logger')],
  init({store, route, context, router}) {
    // context.response.redirect("/news/auto/227689/chto-takoe-avtovykup-i-kakuyu-mashinu-mozhno-prodat.html");

    if (!route.from || route.from.path.indexOf('/24') === -1) {
      store.registerModule('autoRia24', storeModule);
    }

    const { 'lang/prefix': prefix, 'autoRia24/info': info } = store.getters;
    const plateNumber = route.to.params.plateNumber;

    let cookies = {};
    if (context) {
      cookies = context.request.headers.cookie;
    }

    if(_TARGET_ === 'server' || !info['categories.main.id']) {
      store.dispatch('autoRia24/makeSessionId');

      return store
        .dispatch('autoRia24/autofill', {plateNumber, cookies})
        .then(_info => store.dispatch('autoRia24/getTree'))
        .catch((error) => {
          const {response: {status, data: { advertisementId } = {}} = {}} = {} = error;
          if (status === 404 && advertisementId) {
            router.push(prefix + '/24/');
            return;
          }
          throw error;
        });
    }
  },
  metaInfo() {
    return {
      title: seo[this.langId].title,
      meta: [
        {name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW'}
      ],
    };
  },
  computed: {
    ...mapGetters({
      info: 'autoRia24/info',
      advertisementId: 'autoRia24/advertisementId',
      isAutofilled: 'autoRia24/isAutofilled'
    }),
  },
  methods: {
    ...mapActions({
      fetchGearboxes: 'autoRia24/fetchGearboxes',
      fetchBodies: 'autoRia24/fetchBodies',
      fetchDrives: 'autoRia24/fetchDrives',
      fetchRegions: 'autoRia24/fetchRegions',
    }),
    ...mapMutations({
      setInfoField: 'autoRia24/setInfoField'
    }),
    sendAction() {}
  },
  mounted() {
    if(_TARGET_ == 'client') {
      const {query: {blockId = 0, utm_campaign = ''} = {}} = {} = this.$route;

      this.sendInitLog({
        action: 'init',
        page      : '/24/:plate',
        langId    : this.langId,
        blockId,
        info: this.info,
        screenType: this.isMobile ? 'mobile' : 'desktop'
      })

      Promise
          .all([
            this.fetchGearboxes(this.info['categories.main.id']),
            this.fetchBodies(this.info['categories.main.id']),
            this.fetchDrives(this.info['categories.main.id']),
            this.fetchRegions(),
          ])
		const isPromo = Boolean(utm_campaign.match(/(24_sell_)/gim));
		this.setInfoField({key: 'isPromo', value: isPromo});
    }
  },
  components: {
    Contacts,
    TOBlock,
    PriceBlock,
    Advertisement,
    Loader,
    Form
  },
  i18n: {
    messages: {
      ru: {
        'Продайте авто за 24 часа': 'Продайте авто за 24 часа'
      },
      uk: {
        'Продайте авто за 24 часа': 'Продайте авто за 24 години',
      }
    }
  }
}
</script>
<style lang="sass" src="../styles.sass"/>
