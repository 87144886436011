<template>
  <div class="custom-block" style="margin-top: 10px !important;">
    <div class="custom-title size18 bold">
      <CheckedCarSVG />{{ $t('Предварительная оценка авто') }}
    </div>
    <div class="shadow-body">
      <div class="bold price size30" v-if="price">≈ {{ humanizedPrice }} $</div>
      <div class="size16" v-if="price && paintCondition < 3 && techCondition < 3">{{ $t('Рыночное предложение цены будет сформировано на основе осмотра и проверки состояния вашего авто') }}</div>
      <div class="size16" v-else-if="techCondition === 4">{{ $t('Авто непригодно для использования и вероятность выкупа очень низка. Попробуйте, возможно, ваш покупатель именно здесь') }}</div>
      <template v-else-if="paintCondition === 3 || techCondition === 3">
        <div class="size16" style="margin-bottom: 5px">{{ $t('Рыночная цена будет сформирована после осмотра и проверки авто') }}.</div>
        <div class="size16">{{ $t('Ваш автомобиль имеет существенные дефекты и следы износа. Продажа за 24 часа возможна, но вероятность выкупа низкая') }}</div>
      </template>
      <div class="size16" v-else>{{ $t('Недостаточно данных для определения стоимости вашего авто.Укажите больше информации, заполнив форму выше.') }}</div>
      <Description v-if="!advertisementId && price"/>
      <button class="button button-checked size18" @click="submit"><ClockSVG />{{$t('Продать за 24 часа')}}</button>
      <div class="size13">*{{ $t('Менеджер свяжется с Вами в течение 10 минут и уточнит детали об авто') }}</div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'PriceBlock',
  mixins: [require('../../../mixins/humanize')],
  computed: {
    ...mapGetters({
      info: 'autoRia24/info',
      advertisementId: 'autoRia24/advertisementId',
    }),
    humanizedPrice() {
      return this.humanizeNumber(this.price);
    },
    price() {
      const { price: { min = 0 } = {} } = {} = this.info;
      return min;
    },
    techCondition() {
      return this.info['list-technical.condition.id'];
    },
    paintCondition() {
      return this.info['list-paint.condition.id'];
    }
  },
  components: {
    ClockSVG: require('../common/svgs/Clock.vue').default,
    CheckedCarSVG: require('../common/svgs/CheckedCar.vue').default,
    Description: require('./Advertisement/Description/index.vue').default,
  },
  methods: {
    ...mapMutations({
      setInfoField: 'autoRia24/setInfoField'
    }),
    submit() {
      this.$parent.$emit('submit');
    }
  },
  i18n: {
    messages: {
      ru: {
        'Авто непригодно для использования и вероятность выкупа очень низка. Попробуйте, возможно, ваш покупатель именно здесь': 'Авто непригодно для использования и вероятность выкупа очень низка. Попробуйте, возможно, ваш покупатель именно здесь',
        'Рыночная цена будет сформирована после осмотра и проверки авто': 'Рыночная цена будет сформирована после осмотра и проверки авто',
        'Ваш автомобиль имеет существенные дефекты и следы износа. Продажа за 24 часа возможна, но вероятность выкупа низкая': 'Ваш автомобиль имеет существенные дефекты и следы износа. Продажа за 24 часа возможна, но вероятность выкупа низкая',
        'Предварительная оценка авто': 'Предварительная оценка авто',
        'Недостаточно данных для определения стоимости вашего авто.Укажите больше информации, заполнив форму выше.': 'Недостаточно данных для определения стоимости вашего авто. Укажите больше информации, заполнив форму выше.',
        'Мы хотим купить ваш автомобиль примерно за': 'Мы хотим купить ваш автомобиль примерно за',
        'Вы видите первоначальную оценку авто. Окончательное предложение цены мы сможем озвучить после осмотра вашего авто.': 'Вы видите первоначальную оценку авто. Окончательное предложение цены мы сможем озвучить после осмотра вашего авто.',
        'Продать за 24 часа': 'Продать за 24 часа',
        'Рыночное предложение цены будет сформировано на основе осмотра и проверки состояния вашего авто': 'Рыночное предложение цены будет сформировано на основе осмотра и проверки состояния вашего авто',
        'Менеджер свяжется с Вами в течение 10 минут и уточнит детали об авто': 'Менеджер свяжется с Вами в течение 10 минут и уточнит детали об авто',
      },
      uk: {
        'Авто непригодно для использования и вероятность выкупа очень низка. Попробуйте, возможно, ваш покупатель именно здесь': 'Авто не придатне для використання і вірогідність викупу дуже низька. Спробуйте, можливо, ваш покупець саме тут',
        'Рыночная цена будет сформирована после осмотра и проверки авто': 'Ринкова ціна буде сформована після огляду і перевірки авто',
        'Ваш автомобиль имеет существенные дефекты и следы износа. Продажа за 24 часа возможна, но вероятность выкупа низкая': 'Ваш автомобіль має суттєві дефекти та сліди зношення. Продаж за 24 години можливий, але вірогідність викупу низька',
        'Предварительная оценка авто': 'Попередня оцінка авто',
        'Недостаточно данных для определения стоимости вашего авто.Укажите больше информации, заполнив форму выше.': 'Недостатньо даних для визначення вартості вашого авто. Вкажіть більше інформації, заповнивши форму вище.',
        'Мы хотим купить ваш автомобиль примерно за': 'Ми хочемо придбати ваш автомобіль приблизно за',
        'Вы видите первоначальную оценку авто. Окончательное предложение цены мы сможем озвучить после осмотра вашего авто.': 'Ви бачите первісну оцінку авто. Остаточну пропозицію ціни ми зможемо озвучити після огляду вашого авто.',
        'Продать за 24 часа': 'Продати за 24 години',
        'Рыночное предложение цены будет сформировано на основе осмотра и проверки состояния вашего авто': 'Ринкова пропозиція ціни буде сформовано на основі огляду і перевірки стану вашого авто',
        'Менеджер свяжется с Вами в течение 10 минут и уточнит детали об авто': "Менеджер зв'яжеться з Вами протягом 10 хвилин і уточнить деталі про авто",
      }
    }
  }
}
</script>
